import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.css'
import { StaticImage } from "gatsby-plugin-image"

const path = "../../../../static/oferta/"

const Cartiology = () => (
  <Container className="text_container">
  <StaticImage variant="top" src={path + "kardiologia.jpg"} alt="Kartiologia" height={400}/>
    <div className="divider"></div>
    <p>
    Badanie kardiologiczne wykonuje lek. wet. Mirka Ziemba, podczas wizyty kardiologicznej przeprowadzane jest pełne badanie echokardiograficzne oraz pomiar ciśnienia krwi i badanie elektrokardiograficzne (EKG).
    W razie wykrytych chorób układu krążenia lub wad serca zostaje wprowadzone leczenie oraz wydane zalecenia.
    </p>
  </Container>
);

export default Cartiology;
