import React from 'react'
import Layout from '../../components/containers/layout'
import Seo from "../../components/seo/seo"
import Navbar from '../../components/nav/navbar'
import Footer from '../../components/nav/footer'
import Hr from '../../components/hr/hr.js'
import Cartiology from '../../components/containers/services/kardiologia.js'
import { getSrc } from "gatsby-plugin-image"

const seoImage = getSrc("../../static/oferta/kardiologia.jpg")

const Kardiologia = () => (
  <Layout>
    <Seo title="Weterynarz - kardiologia"
         description="Badanie echokardiograficzne oraz pomiar ciśnienia krwi i badanie elektrokardiograficzne"
         image={ seoImage }
    />
    <Navbar />
    <Hr nazwa={ "Kardiologia" }/>
    <Cartiology />
    <Footer />
  </Layout>
)
export default Kardiologia;
